import { BackGround } from '../../components/background';
import { BreadCrumb } from '../../components/breadcrumb';
import { Layout } from '../../components/layout';
import { Seo } from '../../components/seo';
import * as Styles from '../../styles/pages/_vote.module.scss';
import Reload from '/static/reload.svg';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

const CompanyPage = ({ location }) => (
  <Layout location={location}>
    <Seo title='投票' description='投票ページです。' />
    <div className={Styles.vote_bar_ng}>
      <p>投票できませんでした</p>
    </div>
    <BreadCrumb
      list={[
        {
          title: 'TOP',
          link: '/',
        },
      ]}
      currentPage='投票'
    />
    <BackGround>
      <section className={Styles.vote}>
        <div className={Styles.vote__wrap}>
          <div className={Styles.vote__inner}>
            <div className={Styles.vote__img}>
              <StaticImage src='../../images/vote_img.png' alt='' />
            </div>
            <div className={Styles.vote__sp_style}>
              <div>
                <h1>『狂武蔵』主演の坂口拓ゲスト 宮本武蔵を楽しく描こうリミッツで</h1>
                <div className={Styles.vote__box}>
                  <p className={Styles.vote__date}>1月21日(木)</p>
                  <p className={Styles.vote__game}>シーズン1 Match 1 | 第１試合</p>
                  <div className={Styles.vote__category}>
                    <p className={Styles.vote__blue}>刀</p>
                    <p className={Styles.vote__red}>狂気</p>
                  </div>
                </div>
              </div>
              <div className={Styles.vote__match}>
                <div className={Styles.vote__match_set}>
                  <div className={Styles.vote__artist}>
                    <StaticImage src='../../images/jbstyle.jpg' alt='' />
                  </div>
                  <p className={Styles.vote__artist_name}>
                    <Link to='/'>jbstyle.</Link>
                  </p>
                  <p className={Styles.vote__blue_btn}>
                    <Link to='/'>投票する</Link>
                  </p>
                </div>
                <p className={Styles.vote__vs}>VS</p>
                <div className={Styles.vote__match_set}>
                  <div className={Styles.vote__artist}>
                    <StaticImage src='../../images/AKI.png' alt='' />
                  </div>
                  <p className={Styles.vote__artist_name}>
                    <Link to='/'>AKI</Link>
                  </p>
                  <p className={Styles.vote__red_btn}>
                    <Link to='/'>投票する</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className={Styles.vote__reload_btn}>
            <button onClick={() => window.location.reload()}>
              <img src={Reload} className='reload' alt='reload' />
              ページをリロードする
            </button>
          </p>
          <p className={Styles.vote__back_btn}>
            <Link to='/'>TOPに戻る</Link>
          </p>
        </div>
      </section>
    </BackGround>
  </Layout>
);

export default CompanyPage;
